@mixin app-root {
  app-root {
    display: inline-flex;
    min-height: 58px;
  }

  @media screen and (max-width: 780px) {
    app-root {
      display: flex;
    }
  }
}